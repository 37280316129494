@import '../styles/defaults';

.cookies {
    &--popup {
        position: fixed;
        z-index: 10000;
        width: 100%;
        height: 35px;
        bottom: 0;
        background: $cookies-background;
        @media (max-width: 400px) {
            height: auto;
        }
        @media (max-width: 600px) {
            height: auto;
        }
        @media (max-width: 800px) {
            height: auto;
        }
        &__content {
            text-align: center;
            padding-top: 2px;
            padding-bottom: 5px;
            color: #000000;
            @media (max-width: 400px) {
                margin-top: auto;
            }
            @media (max-width: 600px) {
                margin-top: auto;
            }
            @media (max-width: 800px) {
                margin-top: auto;
            }
            a {
                color: #333333;
                font-weight: bold;
                &:hover {
                    color: #000000;
                }
            }
        }
        &__text {
            margin-bottom: 0px;
            margin-top: 0px;

            .ant-btn-primary {
                background-color: $cookies-btn-bg;

                &:hover {
                    background-color: $cookies-btn-bg-hover;
                }
            }
        }
    }
}
