.graph-container {
    font-family: "Sonae-Bold";
    height: 100%;
    background-image: url("/public/images/livepoll-graph-bg.jpg");
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: cover;

    img {
        width: 100px;
        margin-left: 30px;
    }



        .recharts-legend-wrapper {
            padding-left: 10px;
            @media (max-width: 760px) {
                margin-left: 0 !important;
                margin-top: 200px !important;
                padding: 0px 20px;
            }
        }

    .recharts-legend-item-text {
        font-weight: bold;
        font-size: 20px;
    }

    .livepoll-question {
        position: relative;
        top: 50%;
        transform: translateY(-50%);

        .button-wrapper{
          text-align: center;
          margin: 20px;
        }

        h1 {
            font-size: 22px;
            font-weight: bolder;
            text-align: center;
            line-height: normal;
            font-family: Helvetica, sans-serif;
        }

        span {
            line-height: normal;
            font-family: Helvetica, sans-serif;
            font-size: 18px;
        }
    }

    .recharts-layer {
        font-size: 16px;
    }
}


.livepoll-card {
    margin: auto !important;

    .ant-card-body{
      overflow-y: scroll;
      height: 400px;
    }

    .ant-progress-bg {
        height: 12px !important;
    }

    .ant-progress-line {
        font-size: 20px !important;
        margin-top: 30px !important;
    }
}
